import firebase from 'firebase/app';
import { getCompanyById } from '../_apis_/companies';
import { createLog } from '../redux/slices/log';

export const getProceduresByCompany = async (companyId) => {
  const rootRef = firebase.database().ref(`procedures_company/${companyId}`);
  return rootRef;
};

export function getProcedureByCompany(procedureId, companyId) {
  return firebase
    .database()
    .ref()
    .child(`procedures_company/${companyId}/${procedureId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export const updateProcedure = async (procedure, companyId) => {
  const currentProcedure = await getProcedureByCompany(procedure.id, companyId);

  const log = await createLog('procedure', 'edit', procedure.id, currentProcedure, procedure);
  const newProcedure = {
    ...procedure,
    log: { ...procedure.log, [log]: true },
  };

  const updates = {};
  updates[`procedures_company/${companyId}/${procedure.id}`] = newProcedure;

  firebase.database().ref().update(updates);
};

export const createNewProcedure = async (procedure, companyId) => {
  try {
    const rootRef = firebase.database().ref('/');
    const procedures = rootRef.child(`procedures_company/${companyId}`);
    const companies = rootRef.child('companies');
    const parentCompanyRef = companies.child(companyId);
    const parentCompany = await getCompanyById(companyId);

    const newProcedure = await procedures.push(procedure);
    const getProcedureCreated = await getProcedureByCompany(newProcedure.key, companyId);
    const findProcedureCreated = procedures.child(`${newProcedure.key}`);
    await findProcedureCreated.set(getProcedureCreated);

    const companyProcedures = parentCompany.procedures?.filter((item) => item) || [];
    const updatedParentCompany = {
      ...parentCompany,
      procedures: [...companyProcedures, newProcedure.key],
    };

    const update = await parentCompanyRef.update(updatedParentCompany);
    const keys = Object.keys(newProcedure);
    // const log = await createLog('procedure', 'create', keys[0], [], procedure);

    return update;
  } catch (error) {
    console.error(error);
  }
};
export const banishProcedure = async (procedure, companyId) => {
  const rootRef = firebase.database().ref('/');
  const proceduresBanished = rootRef.child(`banished/procedures_company/${companyId}`);

  await proceduresBanished.push(procedure);
};
export const deleteProcedure = async (procedureId, companyId) => {
  const procedureRemoved = await getProcedureByCompany(procedureId, companyId);
  procedureRemoved.status = 'removed';
  await banishProcedure(procedureRemoved, companyId);

  const rootRef = firebase.database().ref('/');

  const procedures = rootRef.child(`procedures_company/${companyId}`);
  const currentProcedureRef = procedures.child(procedureId);
  currentProcedureRef.remove();

  const companies = rootRef.child('companies');
  const parentCompanyRef = companies.child(companyId);
  const parentCompany = await getCompanyById(companyId);
  const proceduresArray = parentCompany?.procedures;

  const companyProcedures = proceduresArray.filter((item) => item !== procedureId);

  const updatedParentCompany = {
    ...parentCompany,
    procedures: companyProcedures,
  };

  const update = await parentCompanyRef.update(updatedParentCompany);
  return update;
};
