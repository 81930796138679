import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { createLog } from '../redux/slices/log';
import { getUserByUid } from '../_apis_/user';

export function getOwnCompanies(userId) {
  const rootRef = firebase.database().ref(`/users/${userId}/companies`);
  return rootRef;
}

export const getCompaniesByUser = async (user) => {
  const companies = [];
  let companyIds = [];
  if (Array.isArray(user.companies)) {
    companyIds = user.companies;
  } else if (typeof user.companies === 'object') {
    companyIds = Object.keys(user.companies);
  }

  await Promise.all(
    companyIds.map(async (id) => {
      await firebase
        .database()
        .ref(`companies/${id}`)
        .once('value')
        .then((snap) => {
          const snapData = snap.val();
          const company = {
            ...snapData,
            id: snap.key,
          };
          if (company.comercialName) {
            companies.push(company);
          }
        });
    })
  );
  return companies;
};

export const getCompany = async (companyId) => {
  return firebase
    .database()
    .ref()
    .child(`companies/${companyId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
};

export const getCompanyById = async (id) => {
  let fetchedCompany = {};
  await firebase
    .database()
    .ref(`companies/${id}`)
    .once('value')
    .then((snap) => {
      const snapData = snap.val();
      const company = {
        ...snapData,
        id: snap.key,
      };
      fetchedCompany = company;
    });

  return fetchedCompany;
};

export const deleteCompany = async (company, id) => {
  // const log = await createLog('company', 'delete', company.id, company, []);
  const rootRef = firebase.database().ref('/');
  const companies = rootRef.child('companies');
  const usersRef = rootRef.child('users');

  // Delete the company from the user owners
  const updatedUsers = await company.authorizedUsersIds.map(async (userId) => {
    const currentUser = await getUserByUid(userId);
    const currentUserRef = usersRef.child(userId);

    delete currentUser.companies[id];

    // const nonDeletedCompanies = currentUser.companies?.filter((item) => item !== company.id);
    const updatedUser = {
      ...currentUser,
      companies: currentUser.companies,
    };
    await currentUserRef.update(updatedUser);
  });
  await Promise.all(updatedUsers);

  // Delete company
  const currentCompanyRef = companies.child(id);
  currentCompanyRef.remove();
};
