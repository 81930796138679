import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { getBranchById } from '../../_apis_/branches';
import { getUserByUid } from '../../_apis_/user';
import { createLog } from './log';
import { getProfileSuccess } from './user';
import { listOfProceduresCR } from '../../constants/selects';
import { createNewProcedure } from '../../services/ProcedureService';
// ----------------------------------------------------------------------

const initialState = {
  currentCompany: '',
  branches: [],
  currentBranch: '',
  oldInvoice: '',
};

const currentBranch = localStorage.getItem('branchSelected');
if (currentBranch) {
  initialState.currentBranch = currentBranch;
}

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // // SET CURRENT COMPANY
    // setCompanyId(state, action) {
    //   state.companyId = action.payload;
    // },
    // // SET CURRENT Branch
    // setBranchId(state, action) {
    //   state.branchId = action.payload;
    // },
    // SET CURRENT Branch
    setCurrentBranch(state, action) {
      // const currentBranch = localStorage.getItem('branchSelected');
      // if (currentBranch) {
      //   state.currentBranch = currentBranch;
      // } else state.currentBranch = action.payload;

      state.currentBranch = action.payload;
      /*       {invoiceNumber} = action.payload;
      state.currentBranch = action.payload; */
    },
    // SET CURRENT Branch
    setCurrentCompany(state, action) {
      // const currentCompany = localStorage.getItem('companySelected');
      // if (currentCompany) {
      //   state.currentCompany = currentCompany;
      // } else state.currentCompany = action.payload;
      state.currentCompany = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setCurrentBranch, setCurrentCompany } = slice.actions;

export function getCompanyById(id) {
  return async (dispatch) => {
    let fetchedCompany = {};
    await firebase
      .database()
      .ref(`companies/${id}`)
      .once('value')
      .then((snap) => {
        const snapData = snap.val();
        const company = {
          ...snapData,
          id: snap.key,
        };
        fetchedCompany = company;
      });

    // dispatch(setCurrentCompany(fetchedCompany));
    return fetchedCompany;
  };
}

export function updateCompany(company) {
  return async (dispatch) => {
    const currentCompany = await dispatch(getCompanyById(company.id));
    const log = await createLog('company', 'edit', company.id, currentCompany, company);
    const newCompany = {
      ...currentCompany,
      ...company,
      log: { ...company.log, [log]: true },
    };

    const updates = {};
    updates[`/companies/${company.id}`] = newCompany;
    updates[`/users/${company.rootUser}/companies/${company.id}/name`] = newCompany.companyName;

    firebase.database().ref().update(updates);

    // dispatch(slice.actions.setCurrentCompany({ name: newCompany.companyName, id: newCompany.id }));
  };
}

export function updateBranch(branch) {
  return async (dispatch) => {
    const currentBranch = await getBranchById(branch.id);
    const log = await createLog('branch', 'edit', branch.id, currentBranch, branch);

    const newBranch = {
      ...branch,
      log: { ...branch.log, [log]: true },
    };

    const updates = {};
    updates[`/branches/${branch.id}`] = newBranch;
    updates[`/companies/${branch.parentCompanyId}/branches/${branch.id}/name`] = newBranch.name;

    firebase.database().ref().update(updates);

    // dispatch(slice.actions.setCurrentBranch(newBranch));
    return branch.id;
  };
}

const createProceduresPerDefault = async (company, companyId, listOfProceduresCR) => {
  console.log(listOfProceduresCR);
  if (company.country === 'Costa Rica' || company.country === 'cr') {
    listOfProceduresCR.forEach(async (element) => {
      // const keys = Object.keys(company);
      await createNewProcedure(element, companyId);
    });
  }
};

export function createNewCompany(company) {
  return async (dispatch) => {
    const newCompanyKey = firebase.database().ref().child('companies').push().key;

    const log = await createLog('company', 'create', newCompanyKey, [], company);
    company = {
      ...company,
      log: { ...company.log, [log]: true },
    };

    const rootUser = await getUserByUid(company.rootUser);
    const updatedRootUser = {
      ...rootUser,
      companies: { ...(rootUser.companies || []), [newCompanyKey]: { name: company.companyName, role: 'owner' } },
    };

    const updates = {};
    updates[`/companies/${newCompanyKey}`] = company;
    updates[`/users/${company.rootUser}`] = updatedRootUser;
    // updates[`/role/${newRoleKey}`] = { name: 'owner', description: 'Owner of the company' };
    // updates[`/company_roles/${newCompanyKey}/${newRoleKey}`] = { name: 'owner' };

    firebase.database().ref().update(updates);

    dispatch(getProfileSuccess(updatedRootUser));
    dispatch(setCurrentCompany(company));
    createProceduresPerDefault(company, newCompanyKey, listOfProceduresCR);

    return newCompanyKey;
  };
}

export function createNewBranch(branch) {
  return async (dispatch) => {
    const rootRef = firebase.database().ref('/');
    const companies = rootRef.child('companies');
    const branches = rootRef.child('branches');
    const parentCompanyRef = companies.child(branch.parentCompanyId);
    const parentCompany = await dispatch(getCompanyById(branch.parentCompanyId));

    const newBranchBody = {
      ...branch,
      authorizedUsersIds:
        branch.rootUser === (parentCompany.rootUser || branch.rootUser)
          ? branch.authorizedUsersIds
          : [branch.rootUser, parentCompany.rootUser],
    };

    const newBranch = await branches.push(newBranchBody);

    const log = await createLog('branch', 'create', newBranch.key, [], branch);
    branch = {
      ...branch,
      id: newBranch.key,
      log: { ...branch.log, [log]: true },
    };
    newBranch.update(branch);

    const companyBranches = parentCompany.branches || [];
    const updatedParentCompany = {
      ...parentCompany,
      branches: { ...companyBranches, [newBranch.key]: { name: branch.name, type: branch.type } },
    };

    await parentCompanyRef.update(updatedParentCompany);
    // dispatch(setCurrentBranch(newBranchBody));
    // dispatch(slice.actions.setCurrentCompany(updatedParentCompany));
    return newBranch.key;
  };
}
