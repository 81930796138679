import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { getCompanyById } from './companies';

export const createNewClient = async (client) => {
  const rootRef = firebase.database().ref('/');
  const companies = rootRef.child('companies');
  const clients = rootRef.child('clients');
  const parentCompanyRef = companies.child(client.companyId);
  const parentCompany = await getCompanyById(client.companyId);

  const newClient = await clients.push(client);

  const companyClients = parentCompany.clients?.filter((item) => item) || [];
  const updatedParentCompany = {
    ...parentCompany,
    clients: [...companyClients, newClient.key]
  };

  await parentCompanyRef.update(updatedParentCompany);
  return newClient;
};

export const getClientsByCompany = async (company) => {
  const clients = [];

  const clientsIds = company.clients || [];
  await Promise.all(
    clientsIds.map(async (id) => {
      await firebase
        .database()
        .ref(`clients/${id}`)
        .once('value')
        .then((snap) => {
          const snapData = snap.val();
          const client = {
            ...snapData,
            id: snap.key
          };
          if (client.companyName) {
            clients.push(client);
          }
        });
    })
  );

  return clients;
};

export const getClientById = async (clientId) => {
  let fetchedClient = {};

  await firebase
    .database()
    .ref(`clients/${clientId}`)
    .once('value')
    .then((snap) => {
      const snapData = snap.val();
      const client = {
        ...snapData,
        id: snap.key
      };

      fetchedClient = client;
    });

  return fetchedClient;
};

export const softDeleteClient = async (client) => {
  const rootRef = firebase.database().ref('/');
  const clients = rootRef.child('clients');

  const currentClientRef = clients.child(client.id);

  const updatedClient = {
    ...client,
    active: false
  };
  currentClientRef.update(updatedClient);
};

export const updateClient = async (uid, client) => {
  const rootRef = firebase.database().ref('/');
  const clients = rootRef.child('clients');
  const currentClientRef = clients.child(uid);

  currentClientRef.update(client);
};
