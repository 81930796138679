export const CURRENCY_OPTIONS = [
  { name: 'USD-Dollar', value: 'dolar' },
  { name: 'Euro', value: 'euro' },
  { name: 'Colón', value: 'colon' },
  { name: 'MXN', value: 'Peso Mexicano' },
  { name: 'QTZ', value: 'Quetzal' },
];

export const PAYMENT_TERMS_OPTIONS = [
  {
    name: 'Cash Payment',
    value: 'cashPayment',
  },
  {
    name: 'Fifteen Days',
    value: '15days',
  },
  {
    name: 'Thirty Days',
    value: '30days',
  },
  {
    name: 'Forty Five Days',
    value: '45days',
  },
  {
    name: 'Sixty Days',
    value: '60days',
  },
];

export const GREETING_OPTIONS = [
  {
    name: 'Mr',
    value: 'Mr',
  },
  {
    name: 'Mrs',
    value: 'Mrs',
  },
  {
    name: 'Dr',
    value: 'Dr',
  },
];

export const IDENTIFICATION_TYPE_OPTIONS = [
  {
    name: 'Physical Identity Card',
    value: 'physicalIdentityCard',
  },
  {
    name: 'Legal Identity Card',
    value: 'legalIdentityCard',
  },
  {
    name: 'Without ID',
    value: 'noId',
  },
];

export const listOfProceduresCR = [
  {
    nameProcedure: 'Trámite Acto General',
    category: 'Acto General',
    detail: '<p>Detalle 1</p>',
    honoraries: '1000',
    taxSaleInfo: '5',
    accountSaleInfo: 'Cuenta de Ventas',
    stamps: [
      {
        stampId: 0,
        stampName: 'Timbre',
        stampPrice: '1000',
        stampPercentage: '1',
      },
    ],
  },
];
