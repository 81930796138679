import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';
import getFieldsModified from '../../utils/getFieldsModified';

const initialState = {
  logs: [],
};
const slice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    getLogsSuccess(state, action) {
      state.logs = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

export function getLogs() {
  return async (dispatch) => {
    try {
      await firebase
        .database()
        .ref.child('/logs')
        .orderByKey('modelId')
        .equalTo('-MuHfUnEDw1XQWZ27xw9')
        .once('value')
        .then((snapshot) => {
          snapshot.forEach((log) => {
            console.log(log);
          });
        });
      // dispatch(slice.actions.getLogsSuccess(response.data.profile));
    } catch (error) {
      console.log(error);
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export const getLogsByModel = async (id) => {
  const logsRef = firebase.database().ref(`/logs`);
  const logs = await logsRef
    .child(id)
    .once('value')
    .then((snap) => snap.val());

  return logs;
  // return logs.sort((a, b) => new Date(b.date) - new Date(a.date));
};

export const createLog = async (model, action, key, oldValue = [], updatedValue = []) => {
  const user = firebase.auth().currentUser;
  console.log('user', user);
  console.log('displayName', user.displayName);
  const fields = getFieldsModified(oldValue, updatedValue);
  const newLog = {
    ...{
      modifierUserName: user.email,
      modifierUserUid: user.uid,
      date: new Date().toISOString(),
      model,
      action,
      fieldsModified: fields,
    },
  };

  // console.log(newLog)

  try {
    const rootRef = firebase.database().ref(`/logs/${key}`);
    const log = await rootRef.push(newLog);
    return log.key;
  } catch (err) {
    console.log(err);
    return false;
  }
};
