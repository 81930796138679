export const PLAN_LIST = [
  {
    id: 'basic',
    title: 'Básico',
    price: '$1.00',
    planId: 'P-0T556063P3199313DML2RJLI',
    sx: { background: 'lightgray' },
  },
  {
    id: 'business',
    title: 'Empresarial',
    price: '$9.45',
    planId: 'P-8RH928197U023162YML2RJ2Y',
    sx: { background: 'lightblue' },
  },
  {
    id: 'premium',
    title: 'Premium',
    price: '$95.00',
    planId: 'P-41S15358CA589935LML2RKQI',
    sx: { background: 'lightyellow' },
  },
];

export const PLAN_OPTIONS = {
  basic: { users: '1', invoices: '3', storage: '10MB' },
  business: { users: '5', invoices: '50', storage: '300MB' },
  premium: { users: '10', invoices: '500', storage: '500MB' },
};
