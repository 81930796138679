import { getCompanyById } from '../services/CompanyService';
import { PLAN_LIST } from '../constants/paymentConstants';
import { createNewPaymentOfASubsctiption, validatePayPalSubscription } from '../services/SubscriptionService';
import { dispatch } from '../redux/store';
import { setCurrentCompany } from '../redux/slices/company';

const castPayPalStringToDate = (myDate) => {
  const parts = myDate.split('T');
  const dates = parts[0].split('-');
  const hours = parts[1].split(':');
  const newDate = new Date(dates[0], dates[1] - 1, dates[2], hours[0], hours[1]);
  return newDate;
};

const getValueOfDate = (myDate) => myDate.getFullYear() * 1000 + myDate.getMonth() * 100 + myDate.getDate();

const hasSubscription = (subscription) => subscription;

const dateHasNoExpired = (subscription) => {
  const currentDate = new Date();
  const finishAt = castPayPalStringToDate(subscription.finishAt);

  const valueOfCurrentDate = getValueOfDate(currentDate);
  const valueOfFinishAt = getValueOfDate(finishAt);

  return valueOfCurrentDate <= valueOfFinishAt;
};

const isRenewalActive = (subscription) => subscription.renewal;

const getPlan = (planId) => {
  PLAN_LIST.forEach((plan) => {
    if (planId === plan.plan) {
      return plan;
    }
  });

  return PLAN_LIST[0];
};

const getSubscriptionStructured = (subscriptionResult, plan, userProfile, companyId) => {
  const subscription = {
    // ...data,
    userId: userProfile.uid,
    planId: plan.id, // basic, business, premium
    planName: plan.title,

    payPalPlanId: subscriptionResult.plan_id,
    price: subscriptionResult.billing_info.last_payment.amount.value,
    payPalSubscriptionId: subscriptionResult.id,
    startedAt: subscriptionResult.start_time,
    finishAt: subscriptionResult.billing_info.next_billing_time,
    renewal: subscriptionResult.status === 'ACTIVE',
    endedAt: '',
    renewalCancelledAt: '',
    companyId,
  };

  return subscription;
};

const updateCompanyInRedux = (company, subscription) => {
  dispatch(
    setCurrentCompany({
      ...company,
      subscription,
    })
  );
};

export default async function validateCompanySubscribed(subscription, currentCompany, userProfile) {
  // no tiene suscripción
  if (!hasSubscription(subscription)) {
    // return false; // dejarlo en true cuando esté en develop para temas de pruebas
    return true;
  }

  // suscripción no ha vencido
  if (dateHasNoExpired(subscription)) {
    return true;
  }

  if (isRenewalActive(subscription)) {
    // Venció el mes de la suscripción pero sigue con renovación activa
    // Revisar si canceló la suscripción desde paypal o si pagó un nuevo mes
    const subscriptionResult = await validatePayPalSubscription(subscription.payPalSubscriptionId);

    if (subscriptionResult.status === 'ACTIVE') {
      const plan = getPlan(subscriptionResult.plan_id);
      const subscription = getSubscriptionStructured(subscriptionResult, plan, userProfile, currentCompany.id);

      const companyFetched = await getCompanyById(currentCompany.id);

      updateCompanyInRedux(companyFetched, subscription);
      await createNewPaymentOfASubsctiption(subscription, companyFetched);
      return true;
    }

    // La suscripción ha sido cancelada, actualizar redux, ver si fue actualizada en firebase
  }

  return false;
}
