import firebase from 'firebase/app';
import { firebaseConfig } from '../config';
import { getPermissionsOfRoleByUid } from './RoleService';
import { getCompany } from './CompanyService';

export function getOwnUsers(userId) {
  const rootRef = firebase.database().ref(`/user_users/${userId}`);
  return rootRef;
}

export const getUserByUid = async (uid) => {
  const { currentUser } = firebase.auth();
  const user = await firebase
    .database()
    .ref(`users/${uid}`)
    .once('value')
    .then((snap) => snap.val());

  return user;
};

export const createUser = async (companyId, roleId, user) => {
  const newUserRef = await firebase.database().ref(`/users/${user.uid}`);
  const permissions = await getPermissionsOfRoleByUid(companyId, roleId);
  const company = await getCompany(companyId);

  const newUser = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    companies: {
      [companyId]: {
        name: company.comercialName,
        role: roleId,
        permissions,
      },
    },
    photoURL: '/static/user/user.png',
  };

  await newUserRef.set({ ...newUser });
};

export const editUser = async (uid, user) => {
  const permissions = await getPermissionsOfRoleByUid(user.role);
  const newUserRef = await firebase.database().ref(`/users/${uid}`);
  await newUserRef.update({ ...user, ...{ permissions } });
};

export const addUsersInUser = async (user) => {
  const { currentUser } = firebase.auth();
  const rootRef = firebase.database().ref(`/user_users/${currentUser.uid}/${user.uid}`);
  const newUser = { email: user.email, name: `${user.firstName} ${user.lastName}` };
  await rootRef.set(newUser);
};

export const editUsersInUser = async (userId, uid, user) => {
  const rootRef = firebase.database().ref(`/user_users/${userId}/${uid}`);
  const newUser = { email: user.email, name: `${user.firstName} ${user.lastName}` };
  await rootRef.update(newUser);
};

export const registerUser = async (user) => {
  const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
  const userAuth = await secondaryApp.auth().createUserWithEmailAndPassword(user.email, user.password);
  secondaryApp.auth().signOut();
  secondaryApp.delete();
  return userAuth.user;
};

export const deleteUser = async (uid, userId) => {
  const rootRef = firebase.database().ref('/');

  // const userRef = rootRef.child(`users/${uid}/${userId}`);
  // userRef.remove();

  const userRef2 = rootRef.child(`user_users/${uid}/${userId}`);
  userRef2.remove();
};
